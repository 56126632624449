import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';

const NewsPage = () => {
  const [language, setLanguage] = useState('en'); 
  const tableName = 'members';
  const primaryKey = 'user_id';

  const keyMappings = {
    user_id: 'User ID',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    mobile_number: 'Mobile Number',
    date_of_birth: 'Date Of Birth',
    tier: 'Tier',
    preferred_language:'Preferred Language',
    created_at: 'Created at',
    updated_at: 'Updated at',
    deleted_at: 'Deleted at',
    points: 'Points',
    fcm_token: 'fcmToken',
  };

  const tableHeaders = [
    // 'user_id',
    'first_name',
    'last_name',
    'email',
    'mobile_number',
    'date_of_birth',
    'tier',
    'preferred_language',
    'created_at',
    'updated_at',
    'deleted_at',
    'points',
  ];

  return (
    <BasePageComponent
      txt="Member Info"
      addItemComponent={AddEditNewsModal}
      itemCardComponent={NewsCard}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} 
      showPagination={true}
      showSearch={true}
      searchPlaceholder="Search by email"
      searchFields="email"
      showAddIcon={false}
      primaryKey={primaryKey}
    />
  );
};

export default NewsPage;

