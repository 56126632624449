// utils/DataTransformer.js

const DataTransformer = (data, tableName) => {

  const transformData = (originalData) => {
    switch (tableName) {
      case 'coupons':
        return transformCouponData(originalData);
      case 'force_update_history':
        return transformForceUpdateData(originalData);
      case 'members':
        return transformMemberData(originalData);
      case 'tutorial_settings':
        return transformTutorialSettingsData(originalData);
      case 'main_banner':
        return transformMainBannerData(originalData);
      case 'home_banner':
        return transformHomeBannerData(originalData);
      case 'latest_news':
        return transformOriginalData(originalData);
      default:
        return transformOriginalData(originalData);
    }
  };

  const transformCouponData = (originalData) => {
    const {
      id,
      points_required,
      effective_start_date,
      effective_end_date,
      redemption_start_date,
      redemption_end_date,
      total_quantity,
      remaining_quantity,
      translations
    } = originalData;

    const transformedTranslations = {};
    translations.forEach(translation => {
      transformedTranslations[translation.language] = {
        image_url: translation.image_url,
        caption: translation.caption,
        detail: translation.detail,
        tnc: translation.tnc
      };
    });

    return {
      coupon_id: id,
      points_required,
      effective_start_date,
      effective_end_date,
      redemption_start_date,
      redemption_end_date,
      total_quantity,
      remaining_quantity,
      translations: transformedTranslations
    };
  };

  const transformForceUpdateData = (originalData) => {
    // Implement force update history specific transformations here if needed
    return originalData;
  };

  const transformMemberData = (originalData) => {
    // Remove 'sort_order' key if it exists
    const { sort_order,translations, ...rest } = originalData;
    return rest;
  };

  const transformTutorialSettingsData = (originalData) => {
    // Remove 'sort_order' key if it exists
    const {
      id,
      sort_order,
      start_date,
      end_date,
      translations
    } = originalData;

    const transformedTranslations = {};
    translations.forEach(translation => {
      transformedTranslations[translation.language] = {
        image_url: translation.image_url,
      };
    });

    return {
      sort_order,
      translations: transformedTranslations,
      tutorial_setting_id: id,
    };
    return originalData;
  };

  const transformMainBannerData = (originalData) => {
    const {
      id,
      sort_order,
      start_date,
      end_date,
      translations
    } = originalData;

    const transformedTranslations = {};
    translations.forEach(translation => {
      transformedTranslations[translation.language] = {
        image_url: translation.image_url,
      };
    });

    return {
      bypass_approval: true,
      main_banner_id: id,
      // sort_order,
      start_date,
      end_date,
      translations: transformedTranslations
    };
  };

  const transformHomeBannerData = (originalData) => {
    const {
      id,
      sort_order,
      start_date,
      end_date,
      translations
    } = originalData;

    const transformedTranslations = {};
    translations.forEach(translation => {
      transformedTranslations[translation.language] = {
        image_url: translation.image_url,
      };
    });

    return {
      bypass_approval: true,
      home_banner_id: id,
      // sort_order,
      start_date,
      end_date,
      translations: transformedTranslations
    };
  };

  const transformLatestNewsData = (originalData) => {

    const { translations, ...rest } = originalData;

      return { 
        ...rest, 
        latest_new_translations: translations 
      };
  };

  const transformOriginalData = (originalData) => {
    if (originalData.translations) {
      // Filter out translations with empty image_url
      const filteredTranslations = Object.entries(originalData.translations).reduce((acc, [lang, translation]) => {
        if (translation.image_url !== '') {
          acc[lang] = translation;
        }
        return acc;
      }, {});
  
      // If all translations were removed, delete the translations property
      if (Object.keys(filteredTranslations).length === 0) {
        const { translations, ...rest } = originalData;
        return rest;
      } else {
        return { ...originalData, translations: filteredTranslations };
      }
    }
  
    return originalData;
  };

  // Transform the data
  let transformedData = transformData(data);

  // Remove translations if they're empty
  if (transformedData.translations && Object.keys(transformedData.translations).length === 0) {
    const { translations, ...rest } = transformedData;
    transformedData = rest;
  }

  return transformedData;
};

export default DataTransformer;