import { MdDisabledByDefault } from 'react-icons/md';
import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditNewsModal = ({ isOpen, onClose, initialData, onSave,isEdit }) => {
  console.log("Initial Data:", initialData)
  const dataStructure = {
    member_id: { type: 'text',label: 'Membe ID', required: false},
    registered_date: { type: 'date', label: 'Registered Date', required: true },
    registered_code: {
      type: 'text',
      label: 'Registered Code',
      required: true
    },
    status:{
      type: "select",
      label: 'Status',
      required: true,
      options: [
            { value: 'Registered', label: 'Registered' },
            { value: 'Attended', label: 'Attended' },
            { value: 'No-Show', label: 'No-Show' },
            { value: 'Canceled', label: 'Canceled' },
            { value: 'Waitlisted', label: 'Waitlisted' },
          ]
    },
  };
  return (
    <BaseAddEditModal
      isEdit={isEdit}
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="event_registrations"
    />
  );
};

export default AddEditNewsModal;








