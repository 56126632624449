import axios from 'axios';

// 创建 axios 实例
const apiClient = axios.create({
  baseURL:'https://cms-uat.rewardgo.info/api/', // 基础 URL，可根据环境变量动态配置
  timeout: 1000000, 
});

// 请求拦截器
apiClient.interceptors.request.use(
  config => {
    // 添加 token，暂时注销
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
apiClient.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    return response.data;
  },
  error => {
    // 对响应错误做点什么
    if (error.response) {
      // 请求已发出，但服务器响应状态码不在 2xx 范围内
      console.error('Error:', error.response.data);
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      console.error('No response received:', error.request);
    } else {
      // 其他错误
      console.error('Request error:', error.message);
    }
    return Promise.reject(error);
  }
);

// 通用请求函数
const request = async (method, url, data = {}, config = {}) => {
  const requestConfig = {
    method,
    url,
    ...config,
  };

  if (method.toLowerCase() === 'get' || method.toLowerCase() === 'delete') {
    requestConfig.params = data; // 对于 GET 和 DELETE 请求，将数据作为查询参数
  } else {
    requestConfig.data = data; // 对于其他请求，将数据放在请求体中
  }

  try {
    const response = await apiClient(requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
};


export const get = (url, params, config) => request('get', url, params, config);
export const post = (url, data, config) => request('post', url, data, config);
export const put = (url, data, config) => request('put', url, data, config);
export const del = (url, params, config) => request('delete', url, params, config);

export default request;
