// {
//   "id": 1,
//   "district_id": 9,
//   "latitude": 22.3120024,
//   "longitude": 114.2185664,
//   "created_at": "2024-04-22T09:56:02.702594+00:00",
//   "updated_at": "2024-04-22T09:56:02.702594+00:00",
//   "store_translation": [
//       {
//           "id": 1,
//           "address": "address en",
//           "remarks": null,
//           "language": "en",
//           "store_id": 1,
//           "created_at": "2024-04-22T09:56:57.551359+00:00",
//           "updated_at": "2024-04-22T09:56:57.551359+00:00",
//           "branch_name": "branch name en",
//           "business_hours": "9:00-6:00"
//       }
//   ]
// }

import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent';
import AddEditStoreModal from './AddEditStoreModal';
import StoreCard from './StoreCard';

const StoresPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'stores';
  const joins = [
    { table: 'stores_translation', fields: '*', foreignKey: 'store_id' },
    // { table: 'district', fields: '*', foreignKey: 'district_id' }
  ];

  const keyMappings = {
    // id: 'ID',
    image_url: 'Image',
    branch_name: 'Branch Name',
    business_hours: 'Business Hours',
    address: 'Address',
    // name: 'District',
    remarks: 'Remarks',
    // latitude: 'Latitude',
    // longitude: 'Longitude',
    // created_at: 'Created At',
    updated_at: 'Last Modify Date'
  };

  const tableHeaders = [
    // 'id',
    'translations.0.image_url',
    'translations.0.branch_name',
    'translations.0.business_hours',
    'translations.0.address', 
    // 'district.name',
    'translations.0.remarks',
    // 'latitude', 
    // 'longitude', 
    // 'created_at', 
    'updated_at'
  ];

  return (
    <BasePageComponent
      txt="Store Info"
      addItemComponent={AddEditStoreModal}
      itemCardComponent={StoreCard}
      showToggleView={false}
      tableName={tableName}
      joins={joins}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
      showPagination={true}
    />
  );
};

export default StoresPage;