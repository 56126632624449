import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent';
import AddEditCouponModal from './AddEditHomeBannerPage';

const HomeBannerPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'home_banner';
  const joins = [
    { table: 'home_banner_translations', fields: '*', foreignKey: 'banner_id' },
  ];

  const keyMappings = {
    image_url: 'Image',
    // language: 'Language',
    sort_order: 'Sort Order',
    start_date: 'Effective Start Date',
    end_date: 'Effective End Date',
  };

  const tableHeaders = [
    'translations.0.image_url',
    // 'translations.0.language',
    'sort_order',
    'start_date', 
    'end_date',
  ];

  return (
    <BasePageComponent
      txt="Home Banner"
      addItemComponent={AddEditCouponModal}
      showToggleView={false}
      tableName={tableName}
      joins={joins}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
    />
  );
};

export default HomeBannerPage;
