import React, { useState } from 'react';
import BasePageComponent from '../../../components/base/BasePageComponent';
import AddEditTutorialSettingsModal from './AddEditTutorialSettingsPage';

const TutorialsPage = () => {
  const [language, setLanguage] = useState('en'); // Default to English
  const tableName = 'tutorial_settings';
  const joins = [
    { table: 'tutorial_settings_translations', fields: '*', foreignKey: 'id' },
  ];

  const keyMappings = {
    image_url: 'Image',
    // language: 'Language',
    sort_order: 'Sort Order',
    created_at: 'Created At',
    updated_at: 'Updated At',
  };

  const tableHeaders = [
    'translations.0.image_url',
    // 'translations.0.language',
    'sort_order',
    'created_at', 
    'updated_at',
  ];

  return (
    <BasePageComponent
      txt="Turorial Image"
      addItemComponent={AddEditTutorialSettingsModal}
      showToggleView={false}
      tableName={tableName}
      joins={joins}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} // Pass key mappings to BasePageComponent
    />
  );
};

export default TutorialsPage;
