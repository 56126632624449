import FullImagePreview from "../banners/PreviewBannerDialog"

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Flex, Select, Spinner, Text, useDisclosure, SimpleGrid, Alert, AlertIcon } from '@chakra-ui/react';
import PageControl from './PageControl';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import CustomTable from './CustomTable'; // Import CustomTable
import Pagination from './Pagination';
import { supabase } from "../../../../api/supabase";
import DataTable from '../../components/base/DataTable'

const BasePageComponent = ({
  txt,
  fetchData,
  addItemComponent: AddEditModal,
  itemCardComponent: ItemCard,
  itemCardListComponent: ItemCardList,
  searchPlaceholder = '',
  filterFields = [],
  sortFields = [],
  defaultSortKey = 'id',
  defaultSortOrder = 'asc',
  showAddIcon = 'always', // Updated prop: can be boolean or 'always', 'never', 'hideWhenData'
  showFilter = true,
  showSearch = false,
  showSort = true,
  showPagination = false,
  showActions = true,
  itemsPerPageOptions = [5, 10, 20, 50],
  tableName,
  joins = [],
  primaryKey = 'id',
  tableHeaders = [], // Custom keys for table headers
  keyMappings = {}, // Key mappings for display names
  saveData, // Function to save data (create/update)
  gridView = false,
  tempData = [],
  columns = [],
  searchFields,
  dateCondition = {
    type: 'none', // 'none', 'single', 'range', or 'custom'
    fields: {
      start: 'startDate',
      end: 'endDate',
      single: 'effectiveDate'
    },
    customLogic: null
  },
  disabledButtons = [], // Array of buttons to disable: ['edit', 'delete']

}) => {
  const [data, setData] = useState(tempData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState(`${defaultSortKey}-${defaultSortOrder}`);
  const [totalPages, setTotalPages] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isGridView, setIsGridView] = useState(gridView);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [alert, setAlert] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEdit, setIsEdit] = useState(null)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);


  // Updated function to determine whether to show the add icon
  const shouldShowAddIcon = useCallback(() => {
    if (typeof showAddIcon === 'boolean') {
      return showAddIcon;
    }
    switch (showAddIcon) {
      case 'never':
        return false;
      case 'hideWhenData':
        return data.length === 0;
      case 'always':
      default:
        return true;
    }
  }, [showAddIcon, data.length]);

  // Checks if the current date is within the effective date range for a given item.
  const isButtonDisabled = useCallback((item, buttonType) => {
    if (!disabledButtons.includes(buttonType)) {
      return false;
    }

    const currentDate = new Date();

    switch (dateCondition.type) {
      case 'single':
        const effectiveDate = new Date(item[dateCondition.fields.single]);
        return currentDate < effectiveDate;

      case 'range':
        const startDate = new Date(item[dateCondition.fields.start]);
        const endDate = new Date(item[dateCondition.fields.end]);
        return currentDate >= startDate && currentDate <= endDate;

      case 'custom':
        return dateCondition.customLogic ? dateCondition.customLogic(item, currentDate) : false;

      case 'none':
      default:
        return false;
    }
  }, [dateCondition, disabledButtons]);

  // Clear alert after 2 seconds
  useEffect(() => {
    if (alert && alert.status === "success") {
      const timer = setTimeout(() => {
        setAlert(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
    handleRefresh();
  }, [alert, currentPage, itemsPerPage]);

  const handleRefresh = useCallback(async () => {
    setLoading(true);
    setSearchQuery('');
    try {
      // 获取数据记录的总数，用于分页计算
      const { count } = await supabase
        .from(tableName)
        .select('*', { count: 'exact' });
  
      setTotalPages(Math.ceil(count / itemsPerPage)); // 设置总页数
  
     
      const offset = (currentPage - 1) * itemsPerPage;
      const limit = itemsPerPage;
  
     
      let result = await global.genericapi.fetchRecords(tableName, joins);
      
      if (filter && filterFields.length > 0) {
        result = result.filter(item => item[filterFields[0].value] === filter);
      }
      const totalRecords = result.length;
      
      setTotalPages(Math.ceil(totalRecords / itemsPerPage)); 
      
      const paginatedData = result.slice(offset, offset + limit);

      if (tableName == 'events'){
        const mergedData = paginatedData.map(item => ({
          ...item,
          registration_period: `${formatDate(item.registration_start) || ''} - ${formatDate(item.registration_end) || ''}`,
          publish_period: `${formatDate(item.publish_start) || ''} - ${formatDate(item.publish_end) || ''}`
        }));
        return  setData(mergedData);
      }
     
      setData(paginatedData);
  
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [joins, filter, filterFields, tableName, currentPage, itemsPerPage]);

  const handleSearch = useCallback(async (e) => {
    const keyword = e.target.value;
    setSearchQuery(keyword);
    setCurrentPage(1); 
    try {
      const searchCondition = `${searchFields}.ilike.%${keyword}%`;
  
      const { count } = await supabase
        .from(tableName)
        .select('*', { count: 'exact' })
        .or(searchCondition);
  
      setTotalPages(Math.ceil(count / itemsPerPage));
  
      const offset = 0; 
      const limit = itemsPerPage;
  
      const { data: result } = await supabase
        .from(tableName)
        .select('*')
        .or(searchCondition)
        .range(offset, offset + limit - 1);
  
      setData(result || []);
  
    } catch (error) {
      console.error("搜索请求失败:", error);
      setError(error);
      setData([]);
    }
  }, [tableName, itemsPerPage]);


  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
    handleRefresh();
  }, []);

  const handleFilterChange = useCallback((e) => {
    setFilter(e.target.value);
    handleRefresh();
  }, []);

  const handleSortChange = useCallback((sortField) => {
    setSort((prevSort) => {
      const [field, direction] = prevSort.split('-');
      const newDirection = field === sortField && direction === 'asc' ? 'desc' : 'asc';
      return `${sortField}-${newDirection}`;
    });
    handleRefresh();
  }, []);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined");
      return;
    }

    const items = e.target.value;
    if (!isNaN(items)) {
      setItemsPerPage(Number(items));
      setCurrentPage(1);
    }
  };

  const handleToggleView = useCallback(() => {
    setIsGridView(!isGridView);
  }, [isGridView]);

  const sortData = (data, sort) => {
    const [field, direction] = sort.split('-');
    return data.sort((a, b) => {
      if (direction === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      }
      return a[field] < b[field] ? 1 : -1;
    });
  };

  const handleDelete = async (item) => {
    setLoading(true);
    try {
      await global.genericapi.deleteRecord(tableName, item[primaryKey], primaryKey);
      // setAlert({ title: "Item deleted successfully", status: "success" });
      handleRefresh();
    } catch (error) {
      setAlert({ title: "Error deleting item", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsEdit(true);
    onOpen();
  };

  const handleAdd = () => {
    setSelectedItem(" ");
    setIsEdit(false);
    onOpen();
    console.log("handleAdd triggered"); 
  };

  const handleView = (item) => {
    console.log(item)
    setPreviewImage(item);
    setIsPreviewOpen(true);
    console.log('setIsPreviewOpen', isPreviewOpen)
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
    setIsPreviewOpen(false);
  };

  const handleFav = async (item) => {
    console.log('handleFav called with item:', item);
    setLoading(true);
    try {
      await global.genericapi.updateRecord(tableName, item[primaryKey], { fav: !item.fav }, primaryKey);
      handleRefresh();
    } catch (error) {
      setAlert({ title: "Error updating favorite", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleEnableDisable = async (item) => {
    console.log('handleEnableDisable called with item:', item);
    setLoading(true);
    try {
      await global.genericapi.updateRecord(tableName, item[primaryKey], { enabled: !item.enabled }, primaryKey);
      handleRefresh();
    } catch (error) {
      setAlert({ title: "Error updating status", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleToggleActive = async (item) => {
    console.log('handleToggleActive called with item:', item);
    setLoading(true);
    try {
      await global.genericapi.updateRecord(tableName, item[primaryKey], { is_active: !item.is_active }, primaryKey);
      handleRefresh();
    } catch (error) {
      setAlert({ title: "Error updating activation status", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (data) => {
    handleRefresh();
  };

  const filteredData = data;
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const formatDate = (isoDateString) => {
    if (!isoDateString) return ''; // 处理空值情况
  
    const date = new Date(isoDateString);
    
    // 使用 getFullYear, getMonth, getDate 等方法生成格式化日期
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，因此 +1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    // 返回格式化的字符串
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };
  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Button key={i} onClick={() => setCurrentPage(i)} isActive={currentPage === i} mx={1} size="sm">
          {i}
        </Button>
      );
    }
    return pages;
  };


  return (
    <Box p={1}>
      {alert && (
        <Alert status={alert.status} mb={1}>
          <AlertIcon />
          {alert.title}
        </Alert>
      )}
      <PageControl
        txt={txt}
        onAddClick={handleAdd}
        onSearchChange={searchPlaceholder?.length == 0 ? null : handleSearch}
        searchPlaceholder={searchPlaceholder?.length == 0 ? null : searchPlaceholder}
        searchQuery={searchPlaceholder?.length == 0 ? null : searchQuery}
        onSearchClear={searchPlaceholder?.length == 0 ? null : handleSearchClear}
        filterFields={filterFields}
        onFilterChange={filterFields?.length == 0 ? null : handleFilterChange}
        filter={filter}
        sortFields={sortFields}
        onSortChange={sortFields?.length == 0 ? null : handleSortChange}
        sort={sort}
        showAddIcon={shouldShowAddIcon()} // Use the updated function here
        showSearch={showSearch}
        showFilter={showFilter}
        showSort={showSort}
        isGridView={isGridView}
        onToggleView={handleToggleView}
        onRefresh={handleRefresh}
      />

      {loading ? (
        <Box w="100%" display="flex" justifyContent="center" alignItems="center" p={1}>
          <Spinner size="xl" />
        </Box>
      ) : error ? (
        <Text color="red.500">{error.message}</Text>
      ) : data.length === 0 ? ( 
        <Text mt={6}>No data available</Text> // Empty data style
      ) : isGridView ? (
        <SimpleGrid columns={[1, null, 3]} spacing={6} mt={6}>
          {paginatedData.map((item) => (
            <ItemCard
              key={item[primaryKey]}
              item={item}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onFav={handleFav}
              onEnableDisable={handleEnableDisable}
              onView={handleView}
              onToggleActive={handleToggleActive}
            />
          ))}
        </SimpleGrid>
      ) : (
        <CustomTable
          data={data || [] }
          tableHeaders={tableHeaders}
          sort={sort}
          handleSortChange={handleSortChange}
          handleView={handleView}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleFav={handleFav}
          handleEnableDisable={handleEnableDisable}
          handleToggleActive={handleToggleActive}
          keyMappings={keyMappings} // Pass key mappings to CustomTable
          columns={columns}
          isEditDisabled={(item) => isButtonDisabled(item, 'edit')}
          isDeleteDisabled={(item) => isButtonDisabled(item, 'delete')}
          currentPage={currentPage} // Add this line
          itemsPerPage={itemsPerPage} 
          showActions = {showActions}
        />
      )}

      {showPagination && (
        <Flex mt={4} justify="space-between" align="center">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            itemsPerPageOptions={itemsPerPageOptions}
            setCurrentPage = {setCurrentPage}
          />
        </Flex>
      )}

      {AddEditModal && (
        <AddEditModal
          isOpen={isOpen}
          onClose={onClose}
          onRefresh={handleRefresh}
          initialData={selectedItem}
          isEdit={isEdit}
          onSave={handleSave}
        />
      )}

      {/* <FullImagePreview isOpen={true} onClose={handleClosePreview} imageUrl={'hwww'} /> */}
      {/* {isPreviewOpen && (
        <FullImagePreview isOpen={true} onClose={handleClosePreview} imageUrl={previewImage} />
      )} */}
      {/* <FullImagePreview isOpen={isPreviewOpen} onClose={handleClosePreview} imageUrl={previewImage} /> */}
    </Box>
  );
};

export default BasePageComponent;









