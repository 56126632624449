// FormFields/ImageField.jsx
import React, { useState , useEffect} from 'react';
import { FormControl, FormLabel, Input, Image, Box } from '@chakra-ui/react';

const ImageField = ({ label, value, onChange, tableName, ...rest }) => {
  const [uploading, setUploading] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  useEffect(() => {
    // 每次value改变时，重置isImageError以重新尝试加载图片
    if (value) {
      setIsImageError(false);
    }
  }, [value]);
  const handleImageChange = async (e) => {
  console.log(value,'handleImageChange image',e)
  
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    try {
      const folder = tableName === 'icon_setting' ? 'icon' : 
                     tableName === 'tutorial_settings' ? 'tutorial' : 'icon';
                     console.log(folder,999999,file)
      const publicUrl = await global.genericapi.uploadImageAndGetPublicUrl(folder, file);
      if (publicUrl) {
        onChange(publicUrl);
        setIsImageError(false);
      }
     
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <FormControl mb={4}>
      <FormLabel>{label}</FormLabel>
      {value && !isImageError ? (
        <Box>
          <Image 
            src={value} 
            boxSize="150px" 
            objectFit="contain" 
            mt={2} 
            onError={() => setIsImageError(true)} // 图片加载失败时触发
          />
        </Box>
      ) : (
        <Box  display="flex" alignItems="center" justifyContent="center" color="gray.500" />
      )}
      <Input
        type="file"
        mt={4}
        h={12}
        py={2}
        px={2}
        onChange={handleImageChange}
        isDisabled={uploading}
        {...rest}
      />
    </FormControl>
  );
};

export default ImageField;