// components/Pagination.js
import React from 'react';
import { Box, Button, Flex, IconButton, Select, Text, HStack, Spacer } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Pagination = ({
    currentPage,
    totalPages,
    onPageChange,
    itemsPerPage,
    setItemsPerPage,
    itemsPerPageOptions,
    setCurrentPage,
}) => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, startPage + 4);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const handleChangePage = (e) => {
        onPageChange(Number(e.target.value));
    };

    const handleItemsPerPageChange = (e) => {
        if (setItemsPerPage) {
            setItemsPerPage(Number(e.target.value));
            setCurrentPage(1);
        } else {
            console.error("setItemsPerPage is not defined");
        }
    };

    return (
        totalPages && (
            <Flex w="100%" wrap="wrap" direction="row" align="center" shadow="sm" borderRadius="lg" justify="space-between"
                borderWidth={1} borderColor={'gray.200'} py={4}
                px={{ base: 4, md: 6 }}>
                <Flex align="center" pt={2}>
                    <Text mr={2}>Items per page:</Text>
                    <Select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        width="auto"
                        variant="outline"
                        size="md"
                    >
                        {itemsPerPageOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </Select>
                </Flex>
                <Flex align="center" wrap="wrap" pt={2}>
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={() => onPageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        aria-label="Previous page"
                        // variant="outline"
                        mx={1}
                        size="md"
                    />
                    {pageNumbers.map((page) => (
                        <Button
                            borderRadius="md"
                            key={page}
                            onClick={() => onPageChange(page)}
                            variant="ghost"
                            color={page === currentPage ? 'white' : 'blue.500'}
                            bg={page === currentPage ? 'blue.500' : ' white'}
                            _hover={{
                                bg: page === currentPage ? 'blue.500' : 'blue.50', // 悬停时的背景色
                            }}
                            mx={1}
                            size="md"
                        >
                            {page}
                        </Button>
                    ))}
                    <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={() => onPageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        aria-label="Next page"
                        // variant="outline"
                        mx={1}
                        size="md"
                    />
                </Flex>
                <Flex  align="center" wrap="wrap" pt={2}>
                    <Select
                        value={currentPage}
                        onChange={handleChangePage}
                        ml={4}
                        width="auto"
                        variant="outline"
                        size="md"
                    >
                        {Array.from({ length: totalPages }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                                Page {i + 1}
                            </option>
                        ))}
                    </Select>
                </Flex>
            </Flex>

        )
    );
};

export default Pagination;
