import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  useBreakpointValue,
  Text
} from '@chakra-ui/react';

import * as FormFields from './FormFields';

import { ensureConvertersInitialized, handleTranslate } from './utils/TranslationHelper';
import { formatTranslationString } from './utils/formatTranslationString';
import DataTransformer from './utils/DataTransformer';
import TableOperations from './utils/TableOperations';

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const BaseAddEditModal = ({
  isOpen,
  onClose,
  onSave,
  initialData,
  dataStructure,
  tableName,
  isEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isTranslatorReady, setIsTranslatorReady] = useState(false);
  const modalSize = useBreakpointValue({ base: "sm", md: "2xl", lg: "4xl" });
  const supportedLanguage = ['en', 'zh-Hant', 'zh-Hans']
  const keysWithTranslateButton = ['caption', 'detail', 'tnc'];

  const translations = {
    en: {
      editItem: "Edit Item",
      addItem: "Add Item"
    },
  };

  const initializeTranslations = () => {
    return supportedLanguage.map(language => ({
      language,
      image_url: '',
      caption: '',
      detail: '',
      tnc: ''
    }));
  };

  const [data, setData] = useState({
    ...initialData || {},
    translations: initialData?.translations || initializeTranslations()
  });

  useEffect(() => {
    if (isOpen) {
      setData({
        ...initialData || {},
        translations: initialData?.translations || initializeTranslations()
      });
      setAlert(null);
      setLoading(false);
    }
  }, [isOpen, initialData]);

  useEffect(() => {
    ensureConvertersInitialized().then(() => setIsTranslatorReady(true));
  }, []);

  const handleChange = useCallback((field, value, language = null) => {
    setData(prevData => {
      if (language) {
        return {
          ...prevData,
          translations: prevData.translations.map(translation =>
            translation.language === language
              ? { ...translation, [field]: value }
              : translation
          ),
        };
      } else {
        return { ...prevData, [field]: value };
      }
    });
  }, []);

  const validateFields = useCallback(() => {
    const newErrors = {};
    Object.entries(dataStructure).forEach(([key, field]) => {
      if (field.required && data[key] === null || data[key] === undefined || data[key] === '') {
        newErrors[key] = `${field.label} is required`;
      } else if (field.validate && !field.validate(data[key])) {
        newErrors[key] = field.validationMessage || `${field.label} is invalid`;
      }
    });

    if (dataStructure.translations && data.translations) {
      data.translations.forEach(translation => {
        Object.entries(dataStructure.translations.fields).forEach(([key, field]) => {
          if (field.required && translation[key] === null || translation[key] === undefined || translation[key] === '') {
            newErrors[`translation-${translation.language}-${key}`] = `${field.label} is required`;
          }
        });
      });
    }

    return newErrors;
  }, [data, dataStructure]);

  const handleSave = useCallback(async () => {

    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setAlert({
        title: 'Validation Error',
        description: Object.values(newErrors).join('\n'),
        status: 'error',
      });
      return;
    }

    setLoading(true);
    setAlert(null);

    try {
      let newData = DataTransformer(data, tableName);

      const operation = TableOperations[tableName] || TableOperations.default;

      const updates = newData;
      const id = initialData.user_id ?? initialData.id;

      if (isEdit) {

        await global.genericapi.updateRecord(tableName, id, updates);
        setAlert({ title: 'Succcess', description: 'Record updated successfully', status: 'success' });

        if (operation === TableOperations.default) {
          await operation.update(newData);
        } else {
          await operation.update(newData);
        }
      } else {
        
        if (tableName == 'events'){
          delete updates['0'];
          delete updates['image'];
          delete updates['publish_period'];
          delete updates['registration_period'];
          await global.genericapi.addRecord(tableName, updates);
  
          setAlert({ title: 'Succcess', description: 'Record updated successfully', status: 'success' });
          return
        }
        await global.genericapi.addRecord(tableName, updates);
  
          setAlert({ title: 'Succcess', description: 'Record updated successfully', status: 'success' });
    
          await operation.add(updates);
       
      }

      onClose();
      onSave();
      window.location.reload();
    } catch (error) {
      setAlert({ title: 'Error', description: error.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  }, [data, initialData, onClose, onSave, tableName, validateFields]);

  const handleTranslateClick = useCallback(async (field, language, currentValue) => {
    if (!isTranslatorReady) {
      console.log('Translator not ready yet');
      return;
    }

    let sourceLanguage, sourceText, targetLanguage;

    // Determine the source language based on the target language
    targetLanguage = language;
    if (language === 'zh-Hant') {
      sourceLanguage = 'zh-Hans';
    } else if (language === 'zh-Hans') {
      sourceLanguage = 'zh-Hant';
    } else {
      // For English or any other language, you might want to set a default behavior
      console.error('Unexpected language for translation');
      return;
    }

    if (currentValue) {
      // Use the current value if the field has text
      sourceText = currentValue;
    } else {
      // Find the source text from the other language translation
      const sourceTranslation = data.translations.find(t => t.language === sourceLanguage);
      if (!sourceTranslation || !(field in sourceTranslation)) {
        console.error(`Field ${field} not found in source translation`);
        return;
      }
      sourceText = sourceTranslation[field];
    }

    if (!sourceText) {
      console.error('No source text available for translation');
      return;
    }

    const translatedText = await handleTranslate(sourceText, sourceLanguage, targetLanguage);
    handleChange(field, translatedText, language);

  }, [data.translations, isTranslatorReady, handleChange]);

  const renderField = useCallback((key, fieldType, language = null) => {
    const { type, label, options, ...rest } = fieldType;
    let value = language
      ? data.translations.find(t => t.language === language)?.[key]
      : data[key];
    // 为日期类型字段提供默认值
    if (type === "date" && (!value || isNaN(new Date(value).getTime()))) {
      value = ""; // 或者使用 new Date() 作为默认日期
    }
   
    const commonProps = {
      key: language ? `${language}-${key}` : key,
      label,
      value: String(value),
      onChange: (value) => handleChange(key, value, language),
      ...rest
    };

    const showTranslateButton = language && keysWithTranslateButton.includes(key);

    if (showTranslateButton) {
      commonProps.showTranslateButton = true;
      commonProps.onTranslate = () => handleTranslateClick(key, language, value);
    }

    switch (type) {
      case 'rich-text':
        return (
          <Box key={key} mb={4}>
            <Text fontWeight={500} mb={2}>{label}</Text> {/* show title */}
            <SunEditor
              setOptions={{
                height: 150, // 设置较小的高度
                zIndex: -9999,
                buttonList: [
                  ['undo', 'redo'],
                  ['font', 'fontSize', 'formatBlock'],
                  ['paragraphStyle', 'blockquote'],
                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                  ['fontColor', 'hiliteColor', 'textStyle'],
                  ['removeFormat'],
                  '/',
                  ['outdent', 'indent'],
                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                  ['table', 'link', 'image', 'video', 'audio' /** ,'math' */],

                  ['fullScreen', 'showBlocks', 'codeView'],
                  ['preview', 'print'],
                  ['save', 'template'],
                ]
              }}
              setContents={String(value || "")}
              onChange={(newContent) => handleChange(key, newContent, language)}
            />
          </Box>
        );
      case 'text':
        return <FormFields.TextField {...commonProps} />;
      case 'textarea':
        return <FormFields.TextareaField {...commonProps} />;
      case 'date':
        return <FormFields.DateField {...commonProps} />;
      case 'dateTime':
        return <FormFields.DateTimeField {...commonProps} />;
      case 'image':
        console.log(value, 'Updated image URL');
        return <FormFields.ImageField {...commonProps} tableName={tableName} />;
      case 'select':
        return <FormFields.SelectField {...commonProps} options={options} />;
      case 'switch':
        return <FormFields.SwitchField {...commonProps} />;
      case 'number':
        return <FormFields.NumberField {...commonProps} />;
      case 'radio':
        return <FormFields.RadioField {...commonProps} options={options} />;
      case 'color':
        return <FormFields.ColorField {...commonProps} />;
      case 'checkbox':
        return <FormFields.CheckboxField {...commonProps} options={options} />;
      default:
        return null;
    }
  }, [data, handleChange, handleTranslateClick]);

  const modalTitle = isEdit
    ? translations.en.editItem
    : translations.en.addItem;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {alert && (
            <Alert status={alert.status} mb={4}>
              <AlertIcon />
              <Box>
                <Box fontWeight="bold">{alert.title}</Box>
                <Box>{alert.description}</Box>
              </Box>
            </Alert>
          )}
          {Object.entries(dataStructure).map(([key, field]) => (
            <Box key={key}>
              {renderField(key, field)}
            </Box>
          ))}
          {dataStructure.translations && (
            supportedLanguage.map(language => (
              <Box key={language} mt={4}>
                <Box fontWeight="bold" mb={2}>
                  {formatTranslationString(language)}
                </Box>
                {Object.entries(dataStructure.translations.fields).map(([key, field]) => (
                  <Box key={key}>
                    {renderField(key, field, language)}
                  </Box>
                ))}
              </Box>
            ))
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSave} ml={3} isLoading={loading}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BaseAddEditModal;