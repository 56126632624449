import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  Input,
  SimpleGrid,
  VStack,
  InputGroup,
  InputLeftElement,
  Container,
  useColorModeValue,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Spinner,
  Center,
  Heading,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon, ChevronDownIcon, CloseIcon, RepeatIcon, TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';
import StatCard from './StatCard';
import DataTablePagination from './DataTablePagination';
import DataTable from '../components/base/DataTable'
import EditMemberModal from './EditMemberModal';
import DeleteMemberAlert from './DeleteMemberAlert';
// Import the axios instance
import { get, post, put, del } from '../../../api/request';


const DashboardPage = () => {
  const [members, setMembers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [recentMembersCount, setRecentMembersCount] = useState(0);
  const [overallMembersCount, setOverallMembersCount] = useState(0);
  const [filterConfig, setFilterConfig] = useState({ text: '', field: null, value: '' });
  const [sortConfig, setSortConfig] = useState({ field: 'user_id', direction: 'asc' });
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10
   });
  const [timeFilters, setTimeFilters] = useState({ recent: 'today', overall: 'thisMonth' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedMember, setSelectedMember] = useState(null);

  const { isOpen: isUpdateModalOpen, onOpen: onUpdateModalOpen, onClose: onUpdateModalClose } = useDisclosure();
  const { isOpen: isAlertDialogOpen, onOpen: onAlertDialogOpen, onClose: onAlertDialogClose } = useDisclosure();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const columnMap = useMemo(() => ({
    index: { label: '#', filterType: 'text' },
    user_id: { label: 'ID', filterType: 'text' },
    first_name: { label: 'First Name', filterType: 'text' },
    last_name: { label: 'Last Name', filterType: 'text' },
    email: { label: 'Email', filterType: 'text' },
    mobile_number: { label: 'Mobile', filterType: 'text' },
    date_of_birth: { label: 'Birthday', filterType: 'date' },
    tier: { label: 'Tier', filterType: 'text' },
    preferred_language: { label: 'Language', filterType: 'text' },
    fcm_token: { label: 'Fcm Token', filterType: 'text' },
    created_at: { label: 'Create Date', filterType: 'date' },
    updated_at: { label: 'Last Update Date', filterType: 'date' },
  }), []);

  const columnLabels = useMemo(() => 
    Object.fromEntries(Object.entries(columnMap).map(([key, value]) => [key, value.label]))
  , [columnMap]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA');
  };

  const formatLanguage = (languageString) => {
    if (languageString === 'zh-Hant') {
      return 'Traditional Chinese'
    } else if (languageString === 'zh-Hans') {
      return 'Simplified Chinese'
    } else {
      return 'English'
    }
  };
  
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

 // 使用 user_id 来请求总数
 const fetchTotalCount = useCallback(async () => {
  try {
    const userId = localStorage.getItem('userId');
    const response = await post('member/totalCount', { user_id: userId });
    
    const count = response.data.total_count || 0;
    console.log(response.data.total_count,737737737)
    // 更新 totalCount 状态
    setTotalCount(count);
  } catch (error) {
    console.error('Error fetching total count:', error);
  }
}, []);

  const processMembers = useCallback((members) => {
    return members.map((member, index) => {
      const { deleted_at, ...rest } = member;
      
      return {
        index: index + 1 + (pagination.currentPage - 1) * pagination.itemsPerPage,
        ...rest,
        date_of_birth: formatDate(rest.date_of_birth),
        created_at: formatDate(rest.created_at),
        updated_at: formatDate(rest.updated_at),
        tier: capitalizeFirstLetter(rest.tier),
        preferred_language: formatLanguage(rest.preferred_language)
      };
    });
  }, [pagination.currentPage, pagination.itemsPerPage]);

  const fetchMembers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const options = {
        filterConfig,
        sortConfig,
        pagination: {
          currentPage: pagination.currentPage,
          itemsPerPage: pagination.itemsPerPage,
        },
        searchFields: ['email']
      };

      const { data } = await global.genericapi.fetchRecordsNew('members', options);
      setMembers(data);
    } catch (error) {
      console.error('Error fetching members:', error);
      setError(error.message || 'Failed to fetch members. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [filterConfig, sortConfig, pagination]);

  const filterOptionIds = {
    recent: { today: 0, '7days': 1, '15days': 2, '30days': 3 },
    overall: { thisMonth: 0, '3months': 1, '6months': 2, '9months': 3, '12months': 4 }
  };
  

  // 请求 recentCount
  const fetchRecentCount = useCallback(async (option_id = "0") => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await post('member/recentCount', { user_id: userId, option_id:String(option_id) });
      setRecentMembersCount(response.total_count || "0");
    } catch (error) {
      console.error('Error fetching recent count:', error);
      setError(error.message || 'Failed to fetch recent count');
    }
  }, []);

  // 请求 overTimeCount
  const fetchOverallCount = useCallback(async (option_id = "0") => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await post('member/overTimeCount', { user_id: userId, option_id:String(option_id) });
      setOverallMembersCount(response.total_count || "0");
    } catch (error) {
      console.error('Error fetching overall count:', error);
      setError(error.message || 'Failed to fetch overall count');
    }
  }, []);

  const getFilteredMembers = useCallback(async (filter) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const filterMap = {
      today: today,
      '7days': new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000),
      '15days': new Date(today.getTime() - 15 * 24 * 60 * 60 * 1000),
      '30days': new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000),
      thisMonth: new Date(today.getFullYear(), today.getMonth(), 1),
      '3months': new Date(today.getFullYear(), today.getMonth() - 3, 1),
      '6months': new Date(today.getFullYear(), today.getMonth() - 6, 1),
      '9months': new Date(today.getFullYear(), today.getMonth() - 9, 1),
      '12months': new Date(today.getFullYear() - 1, today.getMonth(), 1),
    };

    const filterDate = filterMap[filter]?.toISOString();

    if (!filterDate) return 0;

    // try {
    //   const options = {
    //     filterConfig: { field: 'created_at', value: filterDate, operator: 'gte' },
    //     sortConfig: { field: 'created_at', direction: 'desc' }
    //   };
    //   console.log(filter,77878)
    //   const count = await fetchMemberInfo(filter);
    //   // const { count } = await global.genericapi.fetchRecordsNew('members', options);
    //   return count;
    // } catch (error) {
    //   console.error('Error getting filtered members:', error);
    //   return 0;
    // }
  }, []);
  useEffect(() => {
    const recentOptionId = filterOptionIds.recent[timeFilters.recent];
    fetchRecentCount(recentOptionId);
  }, [timeFilters.recent, fetchRecentCount]);

  // 当 timeFilters.overall 改变时，重新请求 overTimeCount API
  useEffect(() => {
    const overallOptionId = filterOptionIds.overall[timeFilters.overall];
    fetchOverallCount(overallOptionId);
  }, [timeFilters.overall, fetchOverallCount]);
  const fetchMemberInfo = async (timeFilter) => {
    try {
      const response = await post('member/totalCount', { key: timeFilter });
      return response; 
    } catch (error) {
      console.error('Error fetching member info:', error);
      throw error;
    }
  };
  
  useEffect(() => {
    fetchTotalCount()
    fetchMembers();
    fetchRecentCount(); // 默认 option_id = 0
    fetchOverallCount(); // 默认 option_id = 0
  }, [fetchMembers],[fetchTotalCount],[fetchRecentCount],[fetchOverallCount]);

  useEffect(() => {
    const updateFilteredCounts = async () => {
      const recentCount = await getFilteredMembers(timeFilters.recent);
      const overallCount = await getFilteredMembers(timeFilters.overall);
      setRecentMembersCount(recentCount);
      setOverallMembersCount(overallCount);
    };

    updateFilteredCounts();
  }, [getFilteredMembers, timeFilters]);

  const handleSort = useCallback((field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handleUpdateClick = (member) => {
    setSelectedMember(member);
    onUpdateModalOpen();
  };
  
  const handleDeleteClick = (member) => {
    setSelectedMember(member);
    onAlertDialogOpen();
  };

  const handleFilterChange = useCallback((field) => {
    setFilterConfig(prev => ({ ...prev, field, value: '' }));
  }, []);

  const handleFilterValueChange = useCallback((value) => {
    setFilterConfig(prev => ({ ...prev, value }));
  }, []);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setFilterConfig(prev => ({ ...prev, text: searchText }));
      fetchMembers();
    }
  };

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  }, []);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setPagination(prev => ({ ...prev, itemsPerPage: newItemsPerPage, currentPage: 1 }));
  }, []);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Center>
    );
  }

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <Container maxW="full" px={[2, 4, 6]}>
        <VStack spacing={[4, 6, 8]} align="stretch">

          {error && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>Error!</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={[4, 6]} width="100%">
            <StatCard
              label="Total Members"
              value={totalCount}
              color="teal.500"
              isEmpty={totalCount === 0}
              emptyMessage="No members"
              bgColor={bgColor}
              borderColor={borderColor}
              tooltip="Total number of registered members"
            />
            <StatCard
              label="Recent Members"
              value={recentMembersCount}
              color="blue.500"
              isEmpty={recentMembersCount === 0}
              emptyMessage="No recent members"
              bgColor={bgColor}
              borderColor={borderColor}
              tooltip="Members who joined recently"
              options={[
                { value: 'today', label: 'Today' },
                { value: '7days', label: '7 Days' },
                { value: '15days', label: '15 Days' },
                { value: '30days', label: '30 Days' },
              ]}
              onOptionChange={(e) => setTimeFilters(prev => ({ ...prev, recent: e.target.value }))}
            />
            <StatCard
              label="Members Over Time"
              value={overallMembersCount}
              color="green.500"
              isEmpty={overallMembersCount === 0}
              emptyMessage="No members in selected period"
              bgColor={bgColor}
              borderColor={borderColor}
              tooltip="Members who joined over a specific time period"
              options={[
                { value: 'thisMonth', label: 'This Month' },
                { value: '3months', label: '3 Months' },
                { value: '6months', label: '6 Months' },
                { value: '9months', label: '9 Months' },
                { value: '12months', label: '12 Months' },
              ]}
              onOptionChange={(e) => setTimeFilters(prev => ({ ...prev, overall: e.target.value }))}
            />
          </SimpleGrid>

          <Box
            bg={bgColor}
            borderRadius="lg"
            boxShadow="md"
            p={[3, 4, 5]}
            borderWidth={1}
            borderColor={borderColor}
            width="100%"
          >
            <VStack spacing={[3, 4, 5]} align="stretch">
              <Flex direction={["column", "row"]} justifyContent="space-between" alignItems={["flex-start", "center"]} wrap="wrap" gap={4}>
                <InputGroup maxW={["full", "300px"]}>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search members email..."
                    value={searchText}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    borderRadius="full"
                  />
                </InputGroup>
                <IconButton
                  variant='action'
                  ml="auto"
                  icon={<RepeatIcon />}
                  aria-label="Refresh"
                  onClick={fetchMembers}
                />
              </Flex>
              
              {filterConfig.field && (
                <Flex direction={["column", "row"]} alignItems={["flex-start", "center"]} wrap="wrap" gap={2}>
                  <Box>Filter by {columnMap[filterConfig.field].label}:</Box>
                  <Input
                    value={filterConfig.value}
                    onChange={(e) => handleFilterValueChange(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') fetchMembers();
                    }}
                    placeholder={`Enter ${columnMap[filterConfig.field].label.toLowerCase()}...`}
                    maxW={["full", "300px"]}
                  />
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => {
                      setFilterConfig(prev => ({ ...prev, field: null, value: '' }));
                      fetchMembers();
                    }}
                    aria-label="Clear filter"
                    size={["sm", "md"]}
                  />
                </Flex>
              )}

              <DataTable
                data={processMembers(members)}
                columnMap={columnLabels}
                sortField={sortConfig.field}
                sortDirection={sortConfig.direction}
                onSort={handleSort}
                onEdit={handleUpdateClick}
                onDelete={handleDeleteClick}
              />
    
              <DataTablePagination
                currentPage={pagination.currentPage}
                totalPages={Math.ceil(totalCount / pagination.itemsPerPage)}
                onPageChange={handlePageChange}
                itemsPerPage={pagination.itemsPerPage}
                setItemsPerPage={handleItemsPerPageChange}
                itemsPerPageOptions={[5, 10, 20, 50]}
              />
              
            </VStack>
          </Box>
        </VStack>
      </Container>

      <EditMemberModal 
        isOpen={isUpdateModalOpen}
        member={selectedMember}
        onClose={onUpdateModalClose}
        onUpdate={fetchMembers}
      />

      <DeleteMemberAlert
        isOpen={isAlertDialogOpen}
        onClose={onAlertDialogClose}
        memberId={selectedMember?.user_id}
        onSuccess={fetchMembers}
        title="Delete Member"
        message="Are you sure you want to delete this member? This action cannot be undone."
      />
    </Box>
    
  );
};

export default DashboardPage;