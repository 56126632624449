export const ColorEnum = {
  PURPLE: "purple",
  ORANGE: "orange",
  GREEN: "green",
  MAGENTA: "magenta",
};

export const colorMapping = {
  [ColorEnum.PURPLE]: "#5D40D1",
  [ColorEnum.ORANGE]: "#F58E54",
  [ColorEnum.GREEN]: "#0CC376",
  [ColorEnum.MAGENTA]: "#A91777",
};

export const configData1 = {
    email: "",
    settings: {
      color_theme_setting: "purple",
      left_menu_settings: [
        {
          item_type: "language_setting",
        },
        {
          item_type: "notification_setting",
        },
        {
          item_type: "member_tier"
        },
        {
          item_type: "about_us",
          url: "/about"
        },
        {
          item_type: "terms_and_conditions",
          url: "/terms"
        }
      ],
      // bottom_navigation_settings: [
      //   {
      //     item_type: "home",
      //     url: "/home"
      //   },
      //   {
      //     item_type: "redeem",
      //     url: "/redeem"
      //   },
      //   {
      //     item_type: "reward",
      //     url: "/reward"
      //   },
      //   {
      //     item_type: "profile",
      //     url: "/profile"
      //   }
      // ],
      about_us: "",
      supported_languages: [
        "en",
        "zh-Hant",
        "zh-Hans"
      ]
    }
  };
  
  export const configData = {
    email: "",
    settings: {
      color_theme_setting: [
        ColorEnum.PURPLE,
        ColorEnum.ORANGE,
        ColorEnum.GREEN,
        ColorEnum.MAGENTA,
      ],
      // bottom_navigation_settings: [
      //   {
      //     item_type: "home",
      //     url: "/home"
      //   },
      //   {
      //     item_type: "redeem",
      //     url: "/redeem"
      //   },
      //   {
      //     item_type: "reward",
      //     url: "/reward"
      //   },
      //   {
      //     item_type: "profile",
      //     url: "/profile"
      //   }
      // ],
      left_menu_settings: [
        {
          item_type: "language_setting",
        },
        {
          item_type: "notification_setting",
        },
        {
          item_type: "member_tier"
        },
        {
          item_type: "about_us",
          url: "/about"
        },
        {
          item_type: "terms_and_conditions",
          url: "/terms"
        }
      ],
      about_us: "",
      supported_languages: [
        "en",
        "zh-Hant",
        "zh-Hans"
      ]
    }
  };

export const colorThemeImages = {
    "purple": "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/tutorial/iPhone%2013%20mini%20-%2027.png", // Replace with actual image URLs
    "orange": "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/tutorial/iPhone%2013%20mini%20-%2031.png",
    "green": "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/tutorial/iPhone%2013%20mini%20-%2035.png",
    "magenta": "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/tutorial/iPhone%2013%20mini%20-%2042.png"
  };