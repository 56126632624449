import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';
import NewsCardList from './NewsCardList';
const NewsPage = () => {
  const [language, setLanguage] = useState('en'); 
  const tableName = 'events';
  const primaryKey = 'id';

  const keyMappings = {
    name: 'Name',
    image_url: 'Image',
    registration_period: 'Registration Period',
    event_date: 'Event Date',
    location: 'Location',
    detail:'Detail',
    publish_period: 'Publish Period',
  };

  const tableHeaders = [
    'name',
    'image_url',
    'registration_period',
    'event_date',
    'location',
    'detail',
    'publish_period',
  ];

  return (
    <BasePageComponent
      txt="Event List"
      addItemComponent={AddEditNewsModal}
      itemCardComponent={NewsCard}
      itemCardListComponent={NewsCardList}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} 
      showPagination={true}
      showSearch={false}
      showAddIcon={true}
      primaryKey={primaryKey}
    />
  );
};

export default NewsPage;

