// TableOperations.js

const defaultOperations = {
  add: async (tableName, data) => {
    return await global.genericapi.addRecord(tableName, data);
  },
  update: async (tableName, id, data, mainKey) => {
    return await global.genericapi.updateRecord(tableName, id, data, mainKey);
  },
  delete: async (tableName, id, mainKey) => {
    return await global.genericapi.deleteRecord(tableName, id, mainKey);
  }
  // await global.genericapi.deleteRecord(tableName, item[primaryKey], primaryKey);
};

const TableOperations = {
  force_update_history: {
    add: (data) => global.genericapi.updateForceRecord(data),
    update: (data) => global.genericapi.updateForceRecord(data),
  },
  coupons: {
    add: (data) => global.genericapi.rpcRecord('create_coupon', data),
    update: (data) => global.genericapi.rpcRecord('update_coupon', data),
  },
  latest_news: {
    add: (data) => global.genericapi.rpcRecord('create_latest_news', data),
    // update: (data) => global.genericapi.rpcRecord('update_latest_news', data),
  },
  main_banner: {
    query: (data) => global.genericapi.rpcRecord('get_main_banners', data),
    add: (data) => global.genericapi.rpcRecord('create_main_banner', data),
    update: (data) => global.genericapi.rpcRecord('update_main_banner', data),
    delete: (data) => global.genericapi.rpcRecord('delete_main_banner', data),
  },
  home_banner: {
    query: (data) => global.genericapi.rpcRecord('get_home_banners', data),
    add: (data) => global.genericapi.rpcRecord('create_home_banner', data),
    update: (data) => global.genericapi.rpcRecord('update_home_banner', data),
    delete: (data) => global.genericapi.rpcRecord('delete_home_banner', data),
  },
  tutorial_settings: {
    query: (data) => global.genericapi.rpcRecord('get_tutorials', data),
    add: (data) => global.genericapi.rpcRecord('create_tutorial', data),
    update: (data) => global.genericapi.rpcRecord('update_tutorial', data),
    delete: (data) => global.genericapi.rpcRecord('delete_tutorial', data),
  },
  cms_user: {
    // query: (data) => global.genericapi.rpcRecord('get_tutorials', data),
    add: (data) => global.genericapi.invoke('cms_user_registration', data),
    // update: (data) => global.genericapi.rpcRecord('update_tutorial', data),
    delete: (data) => global.genericapi.invoke('cms_user_deletion', data),
  },
  // Add more tables as needed

  // Default operations
  default: defaultOperations
};

export default TableOperations;

