import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';

const NewsPage = () => {
  const [language, setLanguage] = useState('en');
  const tableName = 'event_registrations';
  const primaryKey = 'id';

  const keyMappings = {
    member_id: 'Member ID',
    registered_date: 'registered date',
    registered_code: 'registered code',
    status: 'Status',
  };

  const tableHeaders = [
    'member_id',
    'registered_date',
    'registered_code',
    'status',
  ];

  return (

    <BasePageComponent
      txt="Event Registration"
      addItemComponent={AddEditNewsModal}
      itemCardComponent={NewsCard}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings}
      showPagination={true}
      showSearch={true}
      showAddIcon={false}
      searchPlaceholder="Search by status"
      primaryKey={primaryKey}
      searchFields="status"
    />

  );
};

export default NewsPage;

