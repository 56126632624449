import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditMainBannerModal = ({ isOpen, onClose, initialData, onSave }) => {
  const homeBannerDataStructure = {
    translations: {
      fields: {
        image_url: { type: 'image', label: 'Image', required: true },
      }
    },
    sort_order: { type: 'number', label: 'Sort Order', required: false },
    start_date: { type: 'date', label: 'Effective Start Date', required: true },
    end_date: { type: 'date', label: 'Effective End Date', required: true },
  };

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={homeBannerDataStructure}
      tableName="main_banner"
    />
  );
};

export default AddEditMainBannerModal;






