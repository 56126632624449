import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditStoreModal = ({ isOpen, onClose, initialData, onSave }) => {
  const storesDataStructure = {
    district_id: { type: 'number', label: 'District Id', required: true },
    latitude: { type: 'text', label: 'Latitude', required: true },
    longitude: { type: 'text', label: 'Longitude', required: true },
    translations: {
      fields: {
        image_url: { type: 'image', label: 'Image', required: true },
        branch_name: { type: 'text', label: 'Branch Name', required: true },
        business_hours: { type: 'text', label: 'Business Hours', required: true },
        address: { type: 'textarea', label: 'Address', required: true },
        remarks: { type: 'textarea', label: 'Remarks'},
      }
    }
  };

  return (
    <BaseAddEditModal
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={storesDataStructure}
      tableName="stores"
    />
  );
};

export default AddEditStoreModal;
