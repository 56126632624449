import React from 'react';
import { Box, Text, Flex, Button, Image } from '@chakra-ui/react';

const NewsCardList = ({ item, onEdit, onDelete, language }) => {
    const translation = item.translation?.find((trans) => trans.language === language) || '';

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} mb={2}>
      {translation?.title && <Text fontWeight="bold">{translation.title}</Text>}
      {translation?.description && <Text>{translation.description}</Text>}
      {translation?.image_url && <Image src={translation.image_url} alt="News Image" mt={2} />}
      {item?.date && <Text>{item.date}</Text>}
      {item?.category && <Text>{item.category}</Text>}
      <Flex mt={2}>
        <Button size="sm" onClick={onEdit} mr={2}>
          Edit
        </Button>
        <Button size="sm" onClick={onDelete} colorScheme="red">
          Delete
        </Button>
      </Flex>
    </Box>
  );
};

export default NewsCardList;
