import BaseAddEditModal from '../components/base/BaseAddEditModal';
import React from 'react';

const AddEditNewsModal = ({ isOpen, onClose, initialData, onSave,isEdit }) => {
  const dataStructure = {
    start_date: { type: 'date', label: 'Start Date', required: true },
    end_date: { type: 'date', label: 'End Date', required: true },
    translations: {
      fields: {
        url: { type: 'text', label: 'URL' },
        title: { type: 'text', label: 'Title', required: true },
        language: { type: 'text', label: 'Language', required: true },
        image_url: { type: 'image', label: 'Image' },
        description: { type: 'textarea', label: 'Description' }
      }
    }
  };

  return (
    <BaseAddEditModal
      isEdit={isEdit}
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="latest_news"
    />
  );
};

export default AddEditNewsModal;








