import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditNewsModal = ({ isOpen, onClose, initialData, onSave,isEdit }) => {
  console.log("Initial Data:", initialData)
  const dataStructure = {

        first_name: { type: 'text', label: 'First Name', required: true },
        last_name: { type: 'text', label: 'Last Name', required: true },
        email: { type: 'email', label: 'Email', required: true },
        mobile_number: { type: 'number', label: 'mobile number', required: true },
        date_of_birth: {
          type: 'date',
          label: 'Date Of Birth',
          required: true
        },
        tier: {
          type: "select",
          label: 'Tier',
          required: true,
          options: [
            { value: 'basic', label: 'Basic' },
            { value: 'silver', label: 'Silver' },
            { value: 'gold', label: 'Gold' }
          ]
        },
        preferred_language: {
          type: "select",
          label: 'Preferred Language',
          required: true,
          options: [
            { value: 'en', label: 'en' },
            { value: 'zh-Hant', label: 'zh-Hant' },
            { value: 'zh-Hans', label: 'zh-Hans' }
          ]
        },
        points: { type: 'number', label: 'Points', required: true }
     

  };

  return (
    <BaseAddEditModal
      isEdit={isEdit}
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="members"
    />
  );
};

export default AddEditNewsModal;








